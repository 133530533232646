import React from "react"

import "../styles/index.scss"
import "../styles/secondary_pages.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"


import { Link } from "gatsby"







import BannerMobile from "../images/editorial/technology-banner-mobile.jpg"
import Banner from "../images/editorial/technology-banner.jpg"

import ImgDandG from "../images/svg/David-and-Goliath.svg"
import ImgIncomparable from "../images/svg/incomparable-results.svg"
import ImgDashboard from "../images/dashboard-2.png"
import ImgTransparentVisibility from "../images/Transparent-visibility.jpg"
import ImgCustom from "../images/svg/customization.svg"

const Page = () => (

  <React.Fragment>
    <SEO title="Technology" description="Achieving great results requires great technology. MSM technology suite deliverables incomparable financial results." />




    <Layout>

      <section id="sct-uvp" className="container ctr-headline secondary-headline">
        <div className="frame-image">
          <article>

            <h2>Achieving great results requires <span>great technology.</span></h2>
            <p>You may not be a large medical group, but you can still operate like one.</p>

            <button className="btn-primary">
              <a href="#learnmore">Learn More</a>
            </button>
          </article>

          <picture>
            <source srcSet={BannerMobile} media="(max-width:600px)" />
            <source srcSet={Banner} />
            <img src={Banner} alt="" className="lazyload" />
          </picture>


        </div>
      </section>

      <section className="container ctr-potential ctr-newframework" id="sct-newframework">
        <div className="animated ">
          <h2>It’s a David-and-Goliath-esque premise: </h2>
          <p>Small and mid-sized medical practices are up against behemoth insurance companies that make it difficult to get paid what they’re owed. As healthcare management becomes more complex, practices need competitive systems to help with increasing cash flow, reducing denials, and elevating care. </p>

          <p>That’s where MSM comes in. We design, build, and implement cutting-edge technology solutions spanning Revenue Cycle Management, Payor Contracting and Credentialing, Management Services and Consulting, and Practice Management. So even if you’re not an industry giant, you can still operate like one.
</p>



        </div>


        <img src={ImgDandG} />

      </section>
      <a name="learnmore" />
      <section className="container ctrinfoGraphicContainer" >
        <h3>What makes our software stand out from the alternatives?</h3>
        <div className="content">
          <article className="ctrInfoGraphic">
            <div className="article-content">
              <h4>
                Incomparable results
      </h4>
              <p>Our superior technology is how we navigate complicated payor rules and contract terms—and map out how to get you paid as quickly as possible. </p>
            </div>
            <div className="svg svg-vert">
              <img src={ImgIncomparable} />
            </div>
          </article>

          <article className="ctrInfoGraphic">
            <div className="article-content">
              <h4>
                On-demand, in-depth reporting
      </h4>
              <p>We provide real-time, ultra-detailed reporting around key performance indicators. Together, our in-house team and intuitive technology guide you through any questions you might have about trends or metrics. </p>
            </div>
            <div className="svg img-inside">
              <img src={ImgDashboard} alt="" />
            </div>

          </article>
          <article className="ctrInfoGraphic">
            <div className="article-content">
              <h4>
                Transparent visibility
      </h4>
              <p>With MSM, there’s no curtain shielding your data. Access every contract, patient account, and important piece of business intelligence. We’re your partner in transparency and trust.</p>
            </div>
            <div className="svg">
              <img src={ImgTransparentVisibility} alt="" />

            </div>

          </article>
          <article className="ctrInfoGraphic">
            <div className="article-content">
              <h4>
                Intricate customization
      </h4>
              <p>We’re a rare breed of technology company. Not only do we personalize solutions and create integrations to perfectly sync with your existing EHR, but we do it in weeks instead of months.</p>
            </div>
            <div className="svg svg-vert">
              <img src={ImgCustom} />
            </div>

          </article>

        </div>

      </section>

      <section className="container ctr-cta" style={{ backgroundColor: "#16cac3", color: "#fff" }}>
        <h3>No matter which MSM solution is right for your practice.</h3>
        <p> We’ll leverage our cutting-edge technology to bring you superb results. Explore customizable services to expand your physician enterprise today. </p>
        <button className="btn-primary btn-inverse" style={{ backgroundColor: "#fff", color: "#16cac3" }}>
          <Link to="/contact">Learn More</Link>
        </button>
      </section>

    </Layout>
  </React.Fragment >

)

export default Page;